<template>
    <v-card>
        <v-toolbar
                dark
                color="primary"
        >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn v-if="landing"  dark text @click="save">{{ trans('buttons.common.save') }}</v-btn>
                <v-btn v-else  dark text @click="save">{{ trans('buttons.common.create') }}</v-btn>
                <v-btn
                        icon
                        dark
                        @click="$emit('close')"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
            <v-container>
                <v-row v-if="options">
                    <v-col cols="12">
                        <v-select
                            :items="options.offerTypes"
                            :label="trans('fields.common.offerTypes')"
                            :multiple="true"
                            v-model="filters.offerTypes"
                        ></v-select>
                        <v-text-field
                            v-model="filters.label"
                            :label="trans('fields.common.search')"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-select
                            v-model="selectedOffers"
                            :items="filtered"
                            multiple
                            chips
                            deletable-chips
                        ></v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-list flat>
                            <v-list-item-group
                                v-model="selected"
                                multiple
                                active-class=""
                            >
                                <v-list-item
                                    v-for="offer in filtered"
                                    :key="offer.value"
                                    v-show="offer.show"
                                >
                                    <template v-slot:default="{ active }">
                                        <v-list-item-action>
                                            <v-checkbox :input-value="active" dense></v-checkbox>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="offer.text"></v-list-item-title>
                                        </v-list-item-content>
                                    </template>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="$emit('close')">{{ trans('buttons.common.cancel') }}</v-btn>
            <v-btn v-if="landing" color="primary" outlined @click="save">{{ trans('buttons.common.save') }}</v-btn>
            <v-btn v-else color="primary" outlined @click="save">{{ trans('buttons.common.create') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import formValidate from "@/mixins/formValidate";
    export default {
        name: "OfferSelectForm",
        mixins: [formValidate],
        components: {
        },
        props: {
            landing_id: {
                type: Number,
            },
            user_id: {
                type: Number,
            },
            language_id: {
                type: Number,
            },
            country_id: {
                type: Number,
            },
            landing_design_id: {
                type: Number,
            },
        },
        data(){
            return {
                landing: null,
                selected: [],
                options: null,
                filters: {
                    label: '',
                    offerTypes: [],
                }
            }
        },
        created(){
            this.load();
        },
        methods: {
            load(){
                axios.get(this.route('user.landings.show', this.landing_id)).then( response => {
                    this.landing = response.data;
                    this.loadOptions();
                }).catch( err => {
                    console.log(err);
                })
            },
            loadOptions(){
                axios.post(this.route('user.options'), {
                        offerTypes: true,
                        landingOffers: {
                            language_id: this.language_id,
                            country_id: this.country_id,
                            landing_design_id: this.landing_design_id,
                        }
                    }).then( response => {
                    this.options = response.data;
                    this.selected = _.map(this.landing.offerAppearances, (offerAppearance) => {
                        return _.findIndex(this.options.landingOffers, {value: offerAppearance});
                    });

                })
            },
            save() {
                axios.post(this.route('user.landings.syncOffers', this.landing_id), {
                    offers: this.selectedOffers
                }).then( () => {
                    this.$emit('saved');
                    this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveSuccess'), type: 'success'});
                }).catch( err => {
                    this.errors = err.response.data;
                    this.$store.dispatch('showSnack', {text: this.trans('pages.app.saveError'), type: 'error'});
                })
            },
        },
        computed: {
            filtered(){
                if (!this.options) return [];
                if (!this.options.landingOffers.length) return [];
                return _.map(this.options.landingOffers, offer => {
                    offer.show = true;
                    if (this.filters.label){
                        if (offer.text.toLowerCase().indexOf(this.filters.label) === -1) offer.show = false;
                    }
                    if (this.filters.offerTypes.length){
                        if (this.filters.offerTypes.indexOf(offer.type) === -1) offer.show = false;
                    }
                    return offer;
                });
            },
            selectedOffers: {
                get() {
                  let arr = [];
                  _.each(this.selected, (index) => {
                    if (this.options.landingOffers[index]){
                      arr.push(this.options.landingOffers[index].value);
                    }
                  })
                  return arr;
                },
                set(value){
                    let newSelected = this.selected;
                    _.each(this.selected, (index) => {
                        if (typeof index !== 'undefined'){
                            let id = this.options.landingOffers[index].value;
                            if (value.indexOf(id) === -1){
                                newSelected.splice(newSelected.indexOf(index),1);
                            }
                        }
                    })
                    return newSelected;
                }
            },
            formTitle() {
                return this.trans('pages.offer.selectOffersDialogTitle');
            },
        }
    }
</script>
